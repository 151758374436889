import React from 'react'
import Text from '@component/Text/Text'
import {AnnounceType} from '@api/announce/getAnnouncements'
import {useTranslation} from 'next-i18next'

interface IProps {
    className?: string
    type?: AnnounceType | 'new' | 'important'
}

const AnnouncementsTag: React.FC<IProps> = ({className, type}) => {
    const {t} = useTranslation()
    const getStyleBackground = () => {
        switch (type) {
            case 'new':
                return 'bg-primary_tint01 dark:bg-dark_primary_tint01'
            case 'important':
                return 'bg-red_tint01 dark:bg-dark_red_tint01'
            default:
                return 'bg-gray06 dark:bg-dark_gray06'
        }
    }

    const getStyleTextColor = () => {
        switch (type) {
            case 'new':
                return 'text-primary_shade dark:text-dark_primary_shade'
            case 'important':
                return 'text-red_shade dark:text-dark_red_shade'
            default:
                return 'text-gray02 dark:text-dark_gray02'
        }
    }

    const renderText = () => {
        return t(`announcement.tag.${type}`)
    }

    return (
        <Text
            enablePreWhiteSpace={false}
            className={`${getStyleTextColor()} ${getStyleBackground()} text-body3 rounded-[5px] py-[4px] px-[8px] min-h-[26px] ${className}`}>
            {renderText()}
        </Text>
    )
}
export default AnnouncementsTag
