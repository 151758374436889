import React from 'react'
import {BottomSheetProps} from '@component/core/BottomSheetLoader'

interface IProps extends BottomSheetProps {
    isOpened: boolean
}

const WizBottomSheet: React.FC<IProps> = ({isOpened, children, className}) => {
    return (
        <div
            className={`w-full px-[60px] bg-white dark:bg-bg_dark_popup border-t border-t-gray07 dark:border-t-dark_gray07 ${
                isOpened ? 'opacity-1 py-[30px]' : 'opacity-0 py-0 h-0 overflow-hidden'
            } ${className}`}>
            {children}
        </div>
    )
}
export default WizBottomSheet
