import React, {useEffect, useImperativeHandle, useState} from 'react'
import Text from '@component/Text/Text'
import CloseIcon from '@svg/common/ic_close_bold.svg'

interface IProps {
    className?: string
    title?: string
    contentClassName?: string
    children?: JSX.Element
    onCloseCallback?: () => void
    isDimmed?: boolean
}

const WizContentBottomSheet = (
    {className, children, title, contentClassName, onCloseCallback, isDimmed = true}: IProps,
    ref: any,
) => {
    const [isOpen, setIsOpen] = useState(false)

    useImperativeHandle(ref, () => ({
        show: () => {
            showModal()
        },
        close: () => {
            closeModal()
        },
    }))

    const closeModal = () => {
        setIsOpen(false)
        onCloseCallback && onCloseCallback()
    }

    const showModal = () => {
        setIsOpen(true)
    }

    useEffect(() => {
        if (isOpen) {
            if (isDimmed) {
                document?.querySelector('html')?.classList?.add('overflow-y-hidden')
            }
        } else {
            document?.querySelector('html')?.classList?.remove('overflow-y-hidden')
        }
    }, [isOpen, isDimmed])

    return (
        <>
            {isOpen && isDimmed && <Dimmed onClose={closeModal} />}
            <div
                className={`fixed left-0 ${
                    isOpen ? 'h-[80%] bottom-0' : 'h-0 overflow-hidden bottom-[-100px] '
                } max-h-[740px] bg-white dark:bg-bg_dark_popup w-full px-[10px] pt-[10px] pb-[44px] duration-1000 z-modal ${className}`}>
                <div className={'relative px-[10px] py-[5px] flex items-center pb-[10px]'}>
                    <Text className={'text-ti1 text-gray01 dark:text-dark_gray01 flex-1'}>{title}</Text>
                    <div className={'cursor-pointer'} onClick={closeModal}>
                        <CloseIcon className={'fill-gray01 dark:fill-dark_gray01'} width={24} height={24} />
                    </div>
                </div>
                <div className={`${contentClassName}`}>{children}</div>
            </div>
        </>
    )
}

export default React.forwardRef(WizContentBottomSheet)

const Dimmed = ({onClose}) => {
    return (
        <div
            onClick={onClose}
            className={'fixed w-screen h-screen top-0 left-0 bg-black cursor-pointer bg-opacity-40 z-sticky'}
        />
    )
}
