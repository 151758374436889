import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'

interface IImageSignRes {
    image_url: string
    upload_url: string
}

const apiGetImageSign = async (): Promise<ApiRes<IImageSignRes>> => {
    return await apiGet(`imagesign`)
}

export const apiUploadImage = async (file: File) => {
    const {data} = await apiGetImageSign()
    if (data) {
        const {image_url, upload_url} = data

        await fetch(upload_url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'image/png',
                'Cache-Control': 'public, max-age=604800',
            },
            body: file,
        })

        return {
            success: true,
            image_url,
        }
    }

    return {
        success: false,
    }
}
