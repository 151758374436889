import React from 'react'
import {Switch} from '@headlessui/react'

interface IProps {
    className?: string
    checked?: boolean
    onChanged?: (checked: boolean) => void
    disabled?: boolean
}

const WizSwitch: React.FC<IProps> = ({className, checked = false, onChanged, disabled = false}) => {
    return (
        <Switch
            checked={checked}
            onChange={onChanged}
            disabled={disabled}
            className={`${
                disabled
                    ? 'bg-gray07 dark:bg-dark_gray07 border border-gray06 dark:border-dark_gray06'
                    : checked
                    ? 'bg-primary dark:bg-dark_primary'
                    : 'bg-gray05 dark:bg-dark_gray05'
            }
            relative inline-flex h-[24px] w-[48px] box-border shrink-0 items-center cursor-pointer rounded-full duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75
            ${className}`}>
            <span
                className={`${
                    disabled
                        ? 'bg-gray05 dark:bg-dark_gray05 translate-x-7'
                        : checked
                        ? 'translate-x-7'
                        : 'translate-x-1'
                }
            pointer-events-none inline-block h-[16px] w-[16px] transform rounded-full bg-white shadow-normal ring-0 transition duration-200 ease-in-out
            `}
            />
        </Switch>
    )
}

export default WizSwitch
